@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~video.js/dist/video-js.css";
.mat-focus-indicator.mat-menu-item {
  display: flex;
  align-items: center;
  color: black;
  line-height: 36px;
  height: 36px;
}
.mat-focus-indicator.mat-menu-item mat-icon {
  color: black;
  line-height: 16px;
  height: 16px;
  margin-right: 5px;
}